import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import Application from "./Application.js"
import { useGlobalState, logout, passFilter, removeTurkcLow, isEmpty, showErrorMessage } from '../GlobalState.js';
import $ from 'jquery';
import { ajaxFail } from '../GlobalState.js';
import { userInfo } from 'os';

export default function DeletedAppList(props) {

    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');
    const [applist, setApplist] = useState(null);

    const [spinnerClass, setSpinnerClass] = useState("fa fa-refresh");
    const [devamstat, setDevamstat] = useState(null);

    const [surecList, setSurecList] = useState([]);
    const [colnames, setColnames] = useState(["id", "mahalle", "ada", "basvuruTipi", "basvuruDurumu", "basvuruYapan", "basvuruTarihi", "removeComment"]);
    const [sortDirection, setSortDirection] = useState({});


    var sortField = null;


    useEffect(() => {
       
        fetchSurecList();
    }, []);



    const stopSpinner = () => {
        setSpinnerClass("fa fa-refresh")
    }

    const startSpinner = () => {
        setSpinnerClass("spinner-grow spinner-grow-sm")
    }




    const myCompare = (a, b) => {

        var av = a[sortField];
        var bv = b[sortField];

        if (sortField == "basvuruDurumu") {
            av = getDurumName(av);
            bv = getDurumName(bv);
        }

        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && a.appData != null && a.appData.length > 2) {
            var oa = a.appDataObject[sortField];
            if (oa != null && oa.value != null)
                av = oa.value;
        }
        if (bv == null && b.appData != null && b.appData.length > 2) {
            var ob = b.appDataObject[sortField];
            if (ob != null && ob.value != null)
                bv = ob.value;
        }
        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return ret;


        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret;

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return ret;
        }
        return 0;
    }
    const myCompareRev = (a, b) => {
        return -1 * myCompare(a, b)
    }



    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }


        var temp = [...applist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setApplist(temp);
    }
    const getSortedList = (data, temp) => {
        if (data.sort != null && data.sort.length > 0) {
            var sf = data.sort.split(",");
            if (sf.length > 0) {
                for (var i = 0; i < sf.length; i++) {
                    if (sf[i][0] == '-') {
                        sortField = sf[i].substring(1);
                        temp.sort(myCompareRev);
                    }
                    else {
                        sortField = sf[i];
                        temp.sort(myCompare);
                    }
                }
            }

        }
        return temp;
    }




    const getApplicationList = (sablonlist, removed) => {
        fetch("applicationlist?removedstatus=1", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async applist => {
                    setApplist(applist);
                });
            }
        });
    }


    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = textbox.value.toLowerCase();

        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            var ret = true;
            var t1 = removeTurkcLow($(this).text());
            for (let index = 0; index < keywords.length; ++index) {
                var t2 = removeTurkcLow(keywords[index]);
                if (t1.indexOf(t2) == -1)
                    return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });
        document.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";
    }

    const getcurrentFilters = () => {
        var cl = colnames;
        var val = []
        val.push(document.getElementById("mySearchInput").value);
        for (var c = 0; c < cl.length; c++) {
            val.push(document.getElementById(cl[c]).value);
        }
        return val;
    }




    const filterColumn = (root) => {
        var cl = colnames;
        $(root).find("#myTable tr").filter(function () {

            for (var c = 0; c < cl.length; c++) {
                var keywordElem = root.getElementById(cl[c]);
                var td = $(this).children().eq(c);
                if (keywordElem != null && isEmpty(keywordElem.value) == false) {
                    var keyword = keywordElem.value;
                    var pass = true;
                    if (keyword.startsWith("-")) {
                        pass = false
                        keyword = keyword.substr(1);
                    }
                    if (keyword.length == 0) break;
                    td.attr('style', 'background: #fff8dd !important');
                    var t1 = removeTurkcLow(td.text());
                    var t2 = removeTurkcLow(keyword);
                    if ((t1.indexOf(t2) == -1) == pass)
                        return $(this).toggle(false);
                }
                else
                    td.removeAttr('style');
            }
            return $(this).toggle(true);
        });

        if (root.getElementById("totalrec") != null && $("#myTable tr:visible") != null)
            root.getElementById("totalrec").innerHTML = $("#myTable tr:visible").length + " kayıt";
    }




    const fetchSurecList = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);

            }
            else {
                response.json().then(value => {
                    value.hashtable = {};
                    value.forEach(element => value.hashtable[element.id] = element.name);
                    setSurecList(value);
                    getApplicationList()
                })
            }
        });
    }



    const setAppRemovedState = (app, isremoved, delete_comment) => {
        app.removed = isremoved;
        app.removeComment = delete_comment;
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                getApplicationList();
            }
        });
    }



    const deleteApp = (app) => {

        if (app.removed != true) {
            var comment = window.prompt("Lütfen Silme Nedenini Açıklayınız")
            if (comment == null || comment.length == 0) {
                showErrorMessage("Açıklama Boş Olamaz");
                return;
            }
            setAppRemovedState(app, true, comment);
            return;
        }
        fetch("removeapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setApplist(data);
                });
            }
        });
    }


    const getDurumName = (i) => {

        var r = surecList.find(element => element.id == i);
        if (r == null) return i;
        return r.name;
    }

    const reduceTotalNames = (str) => {
        if (isEmpty(str)) return str;
        var parts = str.split(',')
        if (parts.length <= 2)
            return str;
        var vis = parts[0] + " , " + parts[1];
        vis = vis + ", +" + (parts.length - 2);
        return <>  {vis}   <span hidden>{str}</span></>;
    }

    const findRowCount = (sab, tabledata) => {
        return tabledata.length;
    }

    const generateColumnHeader = (c) => {

        var t = c.toUpperCase() == "ADA" ? "ADA/PARSEL" : c.toUpperCase();
        var t = c.toUpperCase() == "REMOVECOMMENT" ? "SİLME NEDENİ" : c.toUpperCase();
        if (t.length > 14) {
            t = t.substr(0, 12) + "...";
        }
        return t;
    }




    const renderToList = (tabledata) => {


        if (tabledata == null || tabledata.length == 0)
            return <div className="w-100 text-center" >Kayıt Yok</div>;




        var devamarray = [];
        if (devamstat != null && devamstat.appState != undefined)
            devamarray = devamstat.appState.split(",");


        var rows = tabledata.map(apprec =>
            <tr key={apprec.id}>
                {colnames.map(c => {
                    if (apprec[c] != null) {
                        if (c == "basvuruDurumu") return <td >{surecList.hashtable[apprec[c]]}</td>
                        else if (c == "müellif") {
                            return <td title={apprec.allmuelliflist}>
                                {apprec[c]}{apprec.muellifCount > 2 ? (", +" + (apprec.muellifCount - 2)) : ""}
                            </td>
                        }
                        else if (c == "kontrolor") {
                            return <td title={apprec.kontrolorNameFamily}>{reduceTotalNames(apprec.kontrolorNameFamily)}</td>
                        }
                        else if (c == "tümKontrolorler") {
                            return <td title={apprec.tümKontrolorler}>{reduceTotalNames(apprec.tümKontrolorler)}</td>
                        }
                        else return <td >{apprec[c]}</td>
                    }
                    try {
                        var appDData = apprec.appDataObject;
                        if (appDData != null && appDData[c] != null) {
                            if (appDData[c].type != null && appDData[c].type == "checkbox") {
                                return <td className="text-center"><input type="checkbox" className="form-check-input p-0 " checked={appDData[c].value} /></td>
                            }
                            return <td >{appDData[c].value}</td>
                        }
                    }
                    catch (ex) {
                        return <td>{ex.toString()}</td>
                    }

                    return <td></td>
                })}

                <td style={{ "overflow": "hidden", "white-space": "nowrap", "font-size": "20px" }}>
                    {apprec.removed == true && role != null && (role.indexOf(UserPermConstants.Basvuru_Silme) > -1 || userName == apprec.basvuruYapan) && <a href="/#" onClick={() => { setAppRemovedState(apprec, false) }} className="text-decoration-none align-middle" ><i className="fa fa-refresh align-middle"></i></a>}
                    {((userName == apprec.basvuruYapan && apprec.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak) || (role != null && role.indexOf(UserPermConstants.Basvuru_Silme) > -1)) && <a href="/#" onClick={() => { deleteApp(apprec) }} className="text-decoration-none material-icons align-middle"  >delete</a>}
                </td>
            </tr>
        );

        var selectedSablonIndex = window.localStorage.getItem('sablon');
        if (selectedSablonIndex == null)
            selectedSablonIndex = 0;

        var mytable =
            <div className="mt-1" key={selectedSablonIndex}>

                <div className="table-responsive mainpagescroll" style={{ "transform": "rotateX(180deg)" }}>
                    <table className='table  table-vdstriped' aria-labelledby="tabelLabel" style={{ "transform": "rotateX(180deg)" }}>
                        <thead>
                            <tr >

                                {colnames.map((c, idx) =>
                                    <th> <div><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList(c)} title={c}> {generateColumnHeader(c)}</button></div>
                                        <div>
                                            <input type="text" className="form-control form-control-sm" id={c} placeholder="" name={c} onChange={() => filterColumn(document)} />
                                        </div></th>
                                )}
                               
                                <th id="totalrec" key={rows.length}>{rows.length} kayıt</th>
                            </tr>
                        </thead>
                        <tbody id="myTable">
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }



    var tabledata = <div class="d-flex justify-content-center"><div class="spinner-border" role="status"></div></div>

    if (applist != null) {
        tabledata = renderToList(applist);
    }


    return <div id="applistroot">
        {/*<div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" hidden={allready == true} ><div className="spinner-border" ></div></div>*/}
        <div className="  align-items-center justify-content-center p-1" >

            <div className="row">
                <div className="col-12 col-md-4">
                </div>
                <div className="col-12 col-md-4">
                    <input className="form-control form-control-sm" id="mySearchInput" type="text" placeholder="Ara" onKeyUp={filterTable} />

                </div>

            </div>
            {tabledata}

        </div>
    </div>


};
