import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { showSuccessMessage, useGlobalState, showErrorMessage, showConfirmMessage } from '../GlobalState.js';
import axios from "axios";
import { ajaxFail } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function MevzuaatSetting(props) {
    var postdata = {};
    postdata["FileType"] = "pdf";
    var selectedRecord = null;
    const [listData, setListData] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    var multiselectRef = React.createRef();
    const [role, setRole] = useGlobalState('role');

    useEffect(() => {
        getHelpList();
        initModal();
    }, [])

    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeyonetmenlik", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec.id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }


    const SendFiles = (event) => {
        document.getElementById("fileuploadsp").hidden = false;



        var selectedFile = { file: event.target.files[0] }
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("appid", selectedRecord.id);
        axios.post(
            "submityonetmenlik",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileuploadsp").hidden = true;
            var locModal = document.getElementById('myModal');
            locModal.style.display = "none";
            locModal.classList.add("hide");
            setListData(res.data);
        })
            .catch(err => { showErrorMessage(err); })
    }


    const initModal = () => {
        var locModal = document.getElementById('myModal');
        var btnclosedown = document.getElementById('downclosemodal');
        btnclosedown.addEventListener('click', (e) => {
            locModal.style.display = "none";
            locModal.classList.add("hide");
        });
    }



    const onSelect = (selectedList, selectedItem) => {
        postdata["roles"] = multiselectRef.current.getSelectedItems().map(c => c.appType).join(", ")
    }
    const onRemove = (selectedList, removedItem) => {
        postdata["roles"] = multiselectRef.current.getSelectedItems().map(c => c.appType).join(", ")
    }

    const fetchUserTypeList = () => {

        fetch("getusertypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setTypeOptions(value);
                })
            }
        });
    }


    const getHelpList = () => {
        fetch("getyonetmenliklist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value)

                })
            }
        });
    }

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const updateMevzuuat = (rec) => {
        fetch("updateyonetmenlik", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Güncelleme Başarılı")
                    setListData(value)
                })
            }
        });
    }


    const Add = () => {
        fetch("addyonetmenlik", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
               //document.getElementById("caption").value = "";
                document.getElementById("url").value = "";
                getHelpList();
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }

    var controlPanel = "";




    const renderTable = (tabledata) => {
        var mytable =

            <table className='table table-vdstriped table-sm text-start' aria-labelledby="tabelLabel">
                <thead className="text-start">
                    <tr>
                        <th width="3%" className="text-start">No</th>
                        <th width="30%" className="text-start">Açıklama</th>
                        <th width="27%" className="text-start">Link</th>
                        <th width="10%" className="text-start">Dosya</th>
                        <th width="10%" className="text-start">Ekleyen</th>
                        <th width="10%" className="text-start">Kayıt Tarihi</th>
                        <th width="10%" className="text-end"></th>
                    </tr>
                </thead>
                <tbody id="myTable">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td className="text-center">{apprec.id}</td>

                               
                                {/*<textarea className="form-control form-control-sm" placeholder="" name="caption" defaultValue={apprec.caption} onChange={(e) => updateRecord(apprec, e)} />*/}

                            <td style={{ "resize": "vertical", "overflow-y": "scroll", "height": "120px", "min-width": "500px" }} className="p-2">
                                <ReactQuill className="h-100" theme="snow" onChange={(e) => apprec.caption = e} defaultValue={apprec.caption} />
                             </td>

                           
                            <td >
                               {/* <div style={{ "overflow-x": "auto", "maxWidth": "400px" }}> <a href={apprec.url} target="_blank" > {apprec.url} </a></div>*/}
                              
                                <textarea className="form-control form-control-sm" placeholder="" name="url" defaultValue={apprec.url} onChange={(e) => updateRecord(apprec, e)} />

                               
                            </td>

                            <td><a href={'getyonetmenlik?id=' + apprec.id} download={apprec.fileName}> {apprec.fileName} </a></td>
                            <td>{apprec.userName}</td>
                            <td>{apprec.postDate}</td>
                             <td>{role.indexOf(UserPermConstants.Yonetmelik_Yukleme) != -1 &&<>
                                <button onClick={() => sil(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>&nbsp;&nbsp;&nbsp;
                                <button onClick={() => updateMevzuuat(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;&nbsp;&nbsp;
                                    <button onClick={() => {
                                        document.getElementById('myModal').style.display = "block";
                                        document.getElementById('myModal').classList.remove("hide");
                                        selectedRecord = apprec;
                                    }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">
                                    Dosya Ekle</button></>}</td>
                        </tr>
                    )}
                    {role.indexOf(UserPermConstants.Yonetmelik_Yukleme) != -1 &&
                        <tr className="bg-white mt-3">
                            <td></td>
                            <td>
                                <ReactQuill className="" theme="snow"  value="" onChange={(e) => postdata.caption =  e}  />

                            </td>
                            <td>
                                <textarea className="form-control form-control-sm" placeholder="Link" id="url" name="url" onChange={handleInputChange} />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                        </tr>}
                </tbody>
            </table>

        return (mytable);
    }

    var listTable = <div class="spinner-border  " ></div>;
    if (listData != null) {
        listTable = renderTable(listData);
    }

    return (
        <div className=" mt-2 rounded">

            <h5>Mevzuat Ayarları</h5>
            <div className="row">
                <div className="col-12 text-center">
                    {listTable}
                </div>
            </div>



            <div className="modal" id="myModal" >
                <div class="modal-dialog modal  modal-dialog-centered ">
                    <div class="modal-content" >
                        <div class="modal-body" id="mymodalbody" >

                            <div className="row">
                                <div className="col-10">

                                    <label for="formFile" class="form-label">Dosya Yukle</label>
                                    <br />
                                    <input class="form-control form-control-sm mt-2" type="file" id="formFile" onChange={(e) => SendFiles(e)} />
                                </div>

                                <div className="col-2 text-end">
                                    <div class="spinner-border  spinner-border-sm" hidden id="fileuploadsp" ></div>
                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" id="downclosemodal" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );



}